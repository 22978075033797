﻿@import '../../../../../Common/src/scss/components/react/CatalogCard.scss';

@mixin border-between($column-count) {
    &:not(:nth-child(#{$column-count}n + #{$column-count})) {
        border-right: 1px solid $gray-light;
    }

    border-bottom: 1px solid $gray-light;

    &:nth-child(#{$column-count}n + 1):nth-last-child(-n + #{$column-count}),
    &:nth-child(#{$column-count}n + 1):nth-last-child(-n + #{$column-count}) ~ .CatalogCard {
        border-bottom: none !important;
    }
}

.CatalogCard {
    @include media-breakpoint-down('md') {
        @include border-between(2);
    }

    @include media-breakpoint-up('lg') {
        @include border-between(3);
    }

    .subheader {
        @include font-size(16px);
        font-weight: bold;
        text-align: center;
    }

    &__prices {
        margin-top: 0;
        margin-bottom: 0.5em;

        .CatalogCard__price {
            font-family: var(--primary-font);
        }
    }

    &__links {
        margin-top: 0;
        margin-bottom: 0.5em;
        text-transform: uppercase;
    }

    .ProductBadge {
        @extend .d-flex;
        @extend .justify-content-center;
    }
}

.divider {
    &.extra-margin {
        //extra margins for when there are no marketing badges present.
        margin-top: 40px;
    }

    @extend .mb-3;
}

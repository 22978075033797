﻿.footer__section {
    padding-top: 60px;
    background-color: var(--primary);
    color: $white !important;

    .footer-heading {
        font-weight: 700;
        @include font-size(14px);
        margin-bottom: 5px;

        &::after {
            content: ' ';
            display: block;
            width: 90px;
            height: 2px;
            background-color: $secondary-blue;
            margin-top: 18px;
        }
    }

    .latest-news {
        padding-left: 0;
        list-style: none;

        .news-item {
            margin-bottom: 5px;
            padding-bottom: 5px;
            border-bottom: 1px $gray-secondary solid;

            .title {
                margin-top: 8px;
                margin-bottom: 3px;
                font-family: var(--tertiary-font);
                font-weight: 500;
                @include font-size(14px);
                text-transform: none;

                a {
                    color: $white;
                }
            }
        }

        & :last-child() {
            border-bottom: none;
        }
    }

    p {
        @include font-size(14px);
    }

    .copyright-row {
        border-top: 1px $white solid;
        font-family: var(--tertiary-font);
        display: block;

        .copyright {
            @include font-size(12px);
            color: $white;
            margin-bottom: 0;
            display: flex;
        }

        .footer__copyright__navgroup {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            display: inline-flex;
            flex-wrap: wrap;

            li.copyright__navgroup__link {
                display: inline;
                margin-left: 0.2em;
                margin-right: 0.2em;
                @include font-size(14px);

                &:not(:first-child)::before {
                    content: '|';
                }

                a {
                    color: $white;
                    vertical-align: top;
                    white-space: nowrap;

                    &:hover {
                        color: $white;
                    }
                }

                @include media-breakpoint-down('sm') {
                    @include font-size(9px, true);

                    a {
                        white-space: nowrap;
                        text-decoration: underline;
                        text-transform: uppercase;
                    }
                }
            }
        }

        @include media-breakpoint-down('sm') {
            border-top: 2px var(--secondary) solid;

            .copyright {
                @include font-size(9px, true);
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-down('md') {
        padding-left: 15% !important;
        padding-right: 15% !important;
        padding-top: 60px;
    }

    @include media-breakpoint-down('xs') {
        padding-left: 5% !important;
        padding-right: 5% !important;
        padding-top: 40px;

        .footer__navgroup {
            padding-right: 0;
            padding-left: 0;

            .subheader {
                @include font-size(16px);
            }
        }
    }

    .icon-tiktokicon {
        height: 22px;
        width: 22px;
    }
}

.footer__navgroup {
    & .accordion ul {
        margin-bottom: 0;
    }

    & .subheader {
        color: $white;
    }

    & .navgroup__links {
        list-style: none;
        padding-left: 0;
        padding-bottom: 5px;
        @include font-size(14);
        padding-top: 10px;
        &.no-padding {
            padding: 0 !important;

            & .navgroup__link a {
                font-size: inherit;
            }
        }
    }

    & .navgroup__link a {
        font-weight: normal !important;
        @include font-size(16);
        line-height: 32px !important;
        color: $white !important;
    }

    .btn {
        padding: 0px;
    }

    .card-header {
        background-color: transparent;
        border: none;
    }

    .accordion {
        margin-bottom: 0.5em;
    }

    .fa-chevron-down {
        color: $white;
        @include font-size(14);
    }
}

.footer__address {
    position: relative;
    padding-left: 1.75rem;
    font-weight: normal;
    @include font-size(14px);

    .footer {
        font-size: 18px;
        line-height: 0.9;
        font-weight: 900;
    }

    i {
        color: $white;
        font-size: 16px;
        position: absolute;
        left: 0;
        margin: 0 0 8px 0;
    }

    p {
        @include font-size(14px);
        margin-bottom: 0;
    }
}

.footer__email {
    font-size: 16px;
    color: $yellow;
}

.footer__social i {
    margin-right: 3px;
    font-size: 36px;
}

.footer__logo {
}

.footer__bottom {
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10%;
    padding-left: 10%;
    font-family: var(--tertiary-font);
}

.footer__bottom a {
    color: $white;
    padding-left: 5px;
}

.footer__subscription {
    background-color: $gray-dark;
    padding-top: 20px;
    padding-bottom: 20px;

    & .alert-custom {
        color: $white !important;
        background-color: $gray-dark !important;
        border-color: $yellow !important;
    }

    & label {
        color: $white !important;
    }

    & button[type='submit'],
    input[type='email'] {
        border-color: $white;
    }

    & button[type='submit'] {
        padding-top: 15px;
        padding-bottom: 11px;
        padding-left: 39px;
        padding-right: 39px;
        border-color: var(--primary);
    }

    input[type='email'] {
        font-size: 1rem;
        padding: 21px 0.7em;
        width: 326px;
    }

    & input[type='email']::-webkit-input-placeholder {
        font-family: var(--tertiary-font);
        letter-spacing: 0.3px;
        color: $black;
        font-weight: normal !important;
        @include font-size(16);
    }

    & input[type='email']::-moz-placeholder {
        font-family: var(--tertiary-font);
        letter-spacing: 0.3px;
        color: $black;
        font-weight: normal !important;
        @include font-size(16);
    }

    & input[type='email']:-moz-placeholder {
        font-family: var(--tertiary-font);
        letter-spacing: 0.3px;
        color: $black;
        font-weight: normal !important;
        @include font-size(16);
    }

    & input[type='email']:-ms-input-placeholder {
        font-family: var(--tertiary-font);
        letter-spacing: 0.3px;
        color: $black;
        font-weight: normal !important;
        @include font-size(16);
    }

    @include media-breakpoint-down('sm') {
        & .subheader,
        input.form-control,
        button[type='submit'] {
            font-size: 16px;
        }

        & label {
            margin-bottom: 0.5rem;
        }

        & button[type='submit'] {
            padding-top: 9px;
            padding-bottom: 5px;
            padding-left: 28px;
            padding-right: 28px;
        }

        input[type='email'] {
            padding: 6px 0.7em;
        }

        & input[type='email']::-webkit-input-placeholder {
            @include font-size(12);
        }

        & input[type='email']::-moz-placeholder {
            @include font-size(12);
        }

        & input[type='email']:-moz-placeholder {
            @include font-size(12);
        }

        & input[type='email']:-ms-input-placeholder {
            @include font-size(12);
        }
    }
}
